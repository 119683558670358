import React from "react"
import "../styles/styles.scss"

import Header from "../components/header"
import Hero from "../components/hero"
import Planning from "../components/planning"
import Benefits from "../components/benefits"
import Features from "../components/features"
import Demo from "../components/demo"
import Footer from "../components/footer"

import {Helmet} from 'react-helmet'

const IndexPage = () => (
  <div>
    <Helmet>
        <html lang="en" />
        <title>Skialabs. Waste management made easy.</title>
        <description>Afval management vergemakkelijkt met het smart waste platform.</description>
      </Helmet>
    <Header />
    <Hero />
    <Planning />
    <Benefits />
    <Features />
    <Demo />
    <Footer />
  </div>
)

export default IndexPage
