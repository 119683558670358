import React from "react"
import { Component } from "react"
import { Link } from "gatsby"
import logo from "../images/logo new.png"
import { FaBars, FaGlobe } from "react-icons/fa"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = { isOpen: false, isOpen2: false, isOpen3: false, class: "inner-header"}
  }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  handleOpen2 = () => {
    this.setState({ isOpen2: true })
  }

  handleClose2 = () => {
    this.setState({ isOpen2: false })
  }

  handleOpen3 = () => {
    if (this.state.isOpen3) {
      this.setState({ isOpen3: false })
    }
    else{
      this.setState({ isOpen3: true})
    }
    
  }

  componentDidMount = () => {
    window.addEventListener('scroll', this.getWindowHeight);
  }

  getWindowHeight = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const shrinkOn = 200;
  
    if (distanceY > shrinkOn) {
      this.setState({ class : "inner-header active" })
    } else {
      this.setState({ class : "inner-header" })
    }
    }

  render() {
    return (
      <header>
      <div className = "container">
      <div className = {this.state.class}>
        <div className = "logo">
          <Link to="/">
          <img src={logo} alt="Skialabs"></img>
          </Link>
        </div>

        <div className="navigation main">
            <div className = 'nav'>
                <button onClick = { this.handleOpen3 }> <FaBars size={30} color={"#000000"}/> 
                {
                 this.state.isOpen3
                 ? (
                  <ul className="menu">
                    <li>
                      Applicaties
                      <ul>
                        <li><Link to="/clientx"> ClientX </Link></li>
                        <li><Link to="/dispatchx"> DispatchX </Link></li>
                        <li><Link to="/beaconx"> BeaconX </Link></li>
                      </ul>
                    </li>
                    <li>
                      Technologie
                      <ul>
                      <li><Link to="/digitaltwin"> Digital twin </Link></li>
                        <li><Link to="/optimization"> Optimalisatie </Link></li>
                        </ul>
                    </li>
                    <li>
                      <a href="/prices">
                        Tarieven
                     </a>
                    </li>
                    <li>
                    <Link to="/index-en">
                      <button className = "language"> <FaGlobe size={12} color={"#000000"}/>  EN </button>
                    </Link>
                    <Link to="/">
                    <button className = "language"> <FaGlobe size={12} color={"#000000"}/>  NL </button>
                  </Link>
                    </li>
                  </ul>
                 )
                 : (
                  null
                 )
                }
                 </button>
            </div>
          </div>
          <div className="navigation">
            <div className = 'nav'>
                <a href="/#" onMouseEnter = { this.handleOpen }
                 onMouseLeave = { this.handleClose } >
                Applicaties
                {
                  this.state.isOpen
                    ? (
                      <ul className="menu">
                        <li><Link to="/clientx"> ClientX </Link></li>
                        <li><Link to="/dispatchx"> DispatchX </Link></li>
                        <li><Link to="/beaconx"> BeaconX </Link></li>
                    </ul>
                    )
                    : (
                      null
                    )
                }   
                </a>
            </div >
            <div className = 'nav'>
                <a href="/#" onMouseEnter = { this.handleOpen2 }
                 onMouseLeave = { this.handleClose2 } >
                Technologie
                {
                  this.state.isOpen2
                    ? (
                      <ul className="menu">
                        <li><Link to="/digitaltwin"> Digital twin </Link></li>
                        <li><Link to="/optimization"> Optimalisatie </Link></li>
                    </ul>
                    )
                    : (
                      null
                    )
                }   
                </a>
              </div>
              <div className = 'nav'>
                <Link to="/prices">Tarieven</Link>
              </div>
              <div className = 'nav'>
                <Link to="/index-en">
                    <button className = "language"> <FaGlobe size={12} color={"#000000"}/> EN </button>
                </Link>
                <Link to="/">
                    <button className = "language"> <FaGlobe size={12} color={"#000000"}/>  NL </button>
                </Link>
              </div>
        </div>
      </div>
      </div>
      </header>
    );
  }
}

export default Header
