import React from "react"
import { Link } from "gatsby"
import logo from "../images/logo new.png"
import Pdf from '../pdf/Privacy_verklaring.pdf';

const Footer = () => {
    return (
    <footer>
        <div className="container">
            <div className="row">
                <div className = "col">
                <Link to="/"><img src={logo} alt="Skialabs"></img></Link>
                </div>
                <div className = "col">
                    <p>Applicaties</p>
                    <ul className="list-unstyled">
                        <li> <Link to="/clientx"> ClientX </Link> </li>
                        <li> <Link to="/dispatchx"> DispatchX </Link>  </li>
                        <li>  <Link to="/beaconx"> BeaconX </Link> </li>
                        <li>  <Link to="/prices"> Tarieven </Link> </li>
                    </ul>
                </div>
                <div className = "col">
                    <p>Technologie</p>
                    <ul className="list-unstyled">
                        <li> <Link to="/digitaltwin"> Digital Twin </Link> </li>
                        <li> <Link to="/optimization"> Flexibele optimalisatie </Link> </li>
                    </ul>
                </div>
            </div>
            <hr/>
            <div className="row">
                <p className="col-sm">
                    2021 Copyright Skialabs | All rights reserved | <a href = {Pdf} target = "_blank" rel="noreferrer">Privacy statement</a>
                </p>
            </div>
        </div>
    </footer>
    );
  }

export default Footer
