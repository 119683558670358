import React from "react"
import { FaPuzzlePiece, FaDatabase, FaShippingFast, FaSortAmountUp} from "react-icons/fa"

const Benefits = () => {
    return(
        <div className="benefits">
        <div className="container">
        <div className="column">
        <div className="row">
                <div className="main-text"> De voordelen. </div>
         </div>
         <div className="row">
         <div className="sub-text"> Wees altijd voorbereid op morgen, minimaliseer verkeersbewegingen en reageer 
                sneller op dynamische wijzigingen in het proces. Met onze cloud-based software heb je altijd toegang tot je 
                applicaties. Met de modulaire componenten passen we de software naadloos aan aan je proces. 
        </div>
        </div>
        <div className="row">
            <div className="column">
                <div className="title">Modulair.</div>
                <div> <FaPuzzlePiece size={50} color={"#7FACD6"}/></div>
            </div>
            <div className="column">
                <div className="title">Dynamisch.</div>
                <div> <FaShippingFast size={50} color={"#7FACD6"}/></div>
            </div>
            <div className="column">
                <div className="title">Altijd optimaal.</div>
                <div> <FaSortAmountUp size={50} color={"#7FACD6"}/></div>
            </div>
            <div className="column">
                <div className="title">Data-gedreven.</div>
                <div> <FaDatabase size={50} color={"#7FACD6"}/></div>
            </div>
        </div>
        </div>
        </div>
        </div>
    )
}

export default Benefits