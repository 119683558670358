import React from "react"
import "../styles/styles.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Demo = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "vehicle.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return(
        <div className="demo">
        <div className="container">
        <div className="row">
        <div className="column">
        <div className="maintext"> Klaar om van start te gaan? </div>
        <div className="row">
        <div className="subtext"> Wil je meer weten over hoe onze software kan helpen bij het inzamelen van reststromen? 
        Neem dan contact op of vraag vrijblijvend een demo aan.  </div>
        </div>
        <div className = "row">
        <a href="mailto:contact@skialabs.com"> <button className="buttondemo">Neem contact op.</button> </a> 
        </div>
        </div>
        <div className="column">
        <Img className = 'sideimage' fluid={data.heroimage.childImageSharp.fluid} />
        </div>
        </div>
        </div>
        </div>
    )
}

export default Demo