import React, { Component } from "react"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa"
import img1 from "../images/skia1.png"
import img2 from "../images/skia2.png"
import img3 from "../images/skia3.png"
import { FaExternalLinkAlt} from "react-icons/fa"

class Features extends Component {
    constructor(props) {
        super(props)
        this.state = { current: 0}
      }

    nextSlide = () => {
        if (this.state.current === 2){
            this.setState({current: 0})
        }
        else {
            this.setState({current: this.state.current+1})
        }
    };

    prevSlide = () => {
        if (this.state.current === 0){
            this.setState({current: 2})
        }
        else {
            this.setState({current: this.state.current-1})
        }
    };

    render() {
        let sliderState = this.state.current;
        let SliderText; 

        if (sliderState ===0 ){
            SliderText = 
            <div className = "slider-styled">
                <img src={img3} alt="CWI"></img>
                <div className = "column">
                <h3>
                Startup in residence: het stroomlijnen van afval inzamelen in Amsterdam.
                </h3>
                <a href="https://www.youtube.com/watch?v=sv_ngdm3_Q0">
                    Kijk hier de video. <FaExternalLinkAlt size={14} color={"#7FACD6"}/>
                </a>
                </div>
            </div>
        }
        else if (sliderState ===1 ) {
            SliderText = 
            <div className = "slider-styled">
                <img src={img2} alt="Bike"></img>
                <div className = "column">
                <h3>
                    Data en machine learning voor het minimaliseren van drukte op de weg. 
                </h3>
                <a href="http://smartcityinnovation.eu/innovation-in-covid-times/">
                    Lees meer. <FaExternalLinkAlt size={14} color={"#7FACD6"}/>
                </a>
                </div>
            </div>
        }
        else if (sliderState ===2 ) {
            SliderText = 
            <div className = "slider-styled">
                <img src={img1} alt="SIA"></img>
                <div className = "column">
                <h3>
                    Smart mobility startup gelanceerd door onderzoekers van het CWI.
                </h3>
                <a href="https://www.cwi.nl/news/2019/smart-mobility-start-up-skialabs-launched-by-cwi-researchers">
                    Lees meer. <FaExternalLinkAlt size={14} color={"#7FACD6"}/>
                </a>
                </div>
            </div>
        }
        return (
            <div className="features">
            <div className="container">
                <div className = "box">
                    <div className="row">
                        <div className="maintext"> Features. </div>
                    </div>
                    <div className="row">
                        <button className="leftArrow" onClick={this.prevSlide}> <FaChevronLeft/> </button>
                        {SliderText}
                        <button className="rightArrow" onClick={this.nextSlide}> <FaChevronRight/> </button>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default Features