import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Img from "gatsby-image"

const Planning = () => {
    const data = useStaticQuery(graphql`
    query {
      dynamische: file(relativePath: { eq: "Group 203.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      planning: file(relativePath: { eq: "client_data.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      inzicht: file(relativePath: { eq: "dispatch_com.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)

    return(
        <div className="planning">
        <div className="container">
        <div className="tabspart">
        <div className="maintext"> Optimale planning, gebaseerd op data. </div>
            <Tabs>
                <TabList>
                    <Tab>Data voor inzicht.</Tab>
                    <Tab>Moeiteloze communicatie.</Tab>
                    <Tab>Dynamische planning.</Tab>
                </TabList>

                <TabPanel>
                  <div className = "text">
                    Met de inzichten uit onze digital twin weet je altijd welke reststromen je waar en wanneer kan verwachten. 
                    Pas je planning aan op de toekomstige scenario's, analyseer de kwaliteit van het opgehaald afval, en
                    communiceer naar de verwerkers welk type reststroom ze wanneer kunnen verwachten voor optimale recycling. 
                  </div> 
                  <div>
                    <Img imgStyle={{objectFit: 'contain'}} fluid={data.planning.childImageSharp.fluid} />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className = "text">
                    Gedurende de dag kan er veel veranderen. Locaties die door werkzaamheden niet 
                    bezocht konden worden, een last-minute lediging bij de klant, een klant die onverhoops toch gesloten is, of een 
                    verwerker die vandaag geen afval kan inzamelen. Met onze applicaties is elke partij altijd op de hoogte van wat speelt.  
                  </div> 
                  <div>
                    <Img imgStyle={{objectFit: 'contain'}} fluid={data.dynamische.childImageSharp.fluid} />
                  </div>
                </TabPanel>
                <TabPanel>
                    <div className = "text">
                      Capaciteitsbeperkingen in je voertuig, rij-restricties in de stad, een elektrische batterij met 
                      een beperkte actie-radius, of een locatie met een tijdsgebonden levering? Hoe complex je proces 
                      er ook uit ziet, onze state-of-the-art optimalisatie zorgt ervoor dat je altijd de optimale 
                      planning krijgt die zich dynamisch aanpast op de gebeurtenissen op de dag zelf. 
                    </div>  
                    <div>
                      <Img imgStyle={{objectFit: 'contain'}} fluid={data.inzicht.childImageSharp.fluid} /> 
                    </div>
                        
                </TabPanel>
            </Tabs>
        </div>
        </div>
    </div>
    )
}

export default Planning